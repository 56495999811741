<template>
    <div class="layout align-center row fill-height pa-4 footer">
        <div>
            <icon
                name="indicator-question"
                size="16px" /> You have no sales attributed to your ads between {{ startDate }} and {{ endDate }}.
            You likely need to upload sales data into {{ platform | capitalize }} to see results at our platform.
            <a :href="links[platform]">Click here to check {{ platform | capitalize }}'s help section.</a>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';

export default {
    name: 'MissingSalesData',
    components: {
        Icon
    },
    props: {
        platform: {
            type: String,
            required: true
        },
        startDate: {
            type: String,
            required: true
        },
        endDate: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            links: {
                pinterest: 'https://help.pinterest.com/en/business/article/upload-conversions-with-ads-manager',
                tiktok: '',
                snapchat: 'https://businesshelp.snapchat.com/s/article/event-file-uploads?language=en_US'
            }
        };
    }
};
</script>

<template>
    <styled-card
        hide-header>
        <div class="stats-wrapper">
            <div class="stats-icon">
                <img :src="`/img/platforms/${channelName.toLowerCase()}.svg`">
            </div>
            <div class="stats-content">
                <h1>{{ channelName }} Stats Processing <span v-if="status === 'failed'">Failed</span></h1>
                <p v-if="status === 'failed'">
                    {{ channelName }} stats processing failed. Sometimes this can be resolved by <a @click.prevent="$emit('retry', channelName.toLowerCase())">retrying</a> processing.
                    If not <a @click.prevent="openConnectionSlideout(channelName.toLowerCase())">click here for additional steps the resolve the issue</a>.
                </p>
                <p v-else>
                    {{ channelName }} stats are currently being populated for this date range.
                    Please check back soon to see your data!
                </p>
                <div class="stats-contact">
                    If you have any questions about your account page please <a @click.prevent="$store.dispatch('createOnboardingSupportTicket')">contact us</a>.
                </div>
            </div>
        </div>
    </styled-card>
</template>

<script>
import StyledCard from '@/components/globals/StyledCard';
import { SET_CHANNEL_CONNECTION_DEBUG_PANEL } from '@/store/mutation-types';

export default {
    name: 'NoMarketplaceData',
    components: {
        StyledCard,
    },
    props: {
        channelName: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        }
    },
    methods: {
        openConnectionSlideout(channel) {
            this.$store.commit(SET_CHANNEL_CONNECTION_DEBUG_PANEL, { status: true, channel, dealer_id: null });
        }
    }
};
</script>

<style lang="scss" scoped>
.stats-wrapper {
    padding: 60px 0;
    padding-right: 20%;
    display: flex;
    background-image: url('/img/robot.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
}
.stats-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
}
.stats-icon img {
    width: 100px;
    height: auto;
}
.stats-content {
    width: 50%;
}
.stats-content h1 {
    font-size: 36px;
    margin-bottom: 15px;
}
.stats-content p {
    font-size: 20px;
    margin-bottom: 60px;
}
.stats-contact {
    margin-bottom: 30px;
}
</style>